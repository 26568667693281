export enum RoutePath {
  // Widget - block
  WIDGET_BOARD_RESCHEDULE = 'bb2f/reschedule',
  WIDGET_BOARD_DONE = 'bb2f/done',
  WIDGET_BOARD_COMMENT = 'bb2f/comment',
  WIDGET_BOARD_TURNOVER_COMMENT = 'bb2f/TurnoverComment',
  WIDGET_BOARD_TURNOVER_DECLINE = 'bb2f/decline',
  WIDGET_BOARD_TURNOVER_INPROGRESS = 'bb2f/inprogress',
  WIDGET_BID_REJECT = 'bb2f/RejectBid', //server use apper case for generated url links, so we should use same here
  WIDGET_BID_ACCEPT = 'bb2f/AcceptBid', //ToDo: make router case insensitive for using normal lowacse links
  WIDGET_BID_APPROVE = 'bb2f/ApproveBid',
  WIDGET_BID_DENY = 'bb2f/DenyBid',
  WIDGET_BID_COMMENT = 'bb2f/CommentBid',
  OPEN_MOBILE_APP = 'open-mobile-app',
  DOWNLOAD_MOBILE_APP = 'download-mobile-app',
  // Service - block (widgets with login)
  RESIDENT = 'resident',
  SERVICE = 'service',
  REQUEST = 'request',
  UPCOMING = 'upcoming',
  RATE = 'rate',
  // Service - end
  ADMIN = 'admin',
  PERFORMANCE_METRICS = 'performance-metrics',
  ADMIN_LEVEL_1 = 'administrator-first-dashboard',
  ADMIN_LEVEL_2 = 'administrator-second-dashboard',
  CONSTRUCTION_MANAGER = 'construction-manager-dashboard',
  PROPERTY_DASHBOARD = 'property-dashboard',
  PROPERTY = 'property',
  UNIT_DASHBOARD = 'unit-dashboard',
  ASSET_DASHBOARD = 'asset-dashboard',
  HOME = 'home',
  ROLES = 'roles',
  LOGIN = 'login',
  VENDOR_DASHBOARD = 'vendor-dashboard',
  ACCEPT_TOKEN = 'accept-token',
  BUILDIUM = 'buildium',
  DELINQUENCIES = 'delinquencies',
  MOVE_OUT = 'move-out',
  MOVE_OUT_PORTFOLIO = 'move-out-portfolio',
  MOVE_OUT_PROPERTY = 'move-out-property',
  // TODO temporary fix - TO BE REMOVED and use full paths instead to navigate
  RENEWALS_LIST = 'renewals-list',
  DELINQUENCY_LIST = 'delinquency-list',
  /* END TODO */
  UNIT_LIST = 'unit-list',
  NO_PERMISSION = 'no-permission',
  NO_PACKAGE = 'no-package',
  //boards - block
  BOARDS = 'boards',
  //Turnovers - block
  TURNOVERS = 'turnovers',
  KANBAN = 'kanban',
  ACTIVE_TURNOVERS = 'active-turnovers',
  ARCHIVED = 'archived',
  NOTICE = 'notice',
  MOVE_OUT_CONFIRMATION = 'move-out',
  BID = 'bid',
  MAKE_READY = 'make-ready',
  RENO = 'reno',
  PUNCH = 'punch',
  APPROVAL = 'approval',
  MARKETING = 'marketing',
  LEASE_UP = 'lease-up',
  MOVE_IN = 'move-in',
  ARCHIVE = 'archive',
  BULK_BIDS = 'bulk-bids',
  //Reports - block
  REPORTS = 'reports',
  Report = 'report',
  RENT_ROLL = 'rent-roll',
  ROC = 'roc',
  BILLING = 'billing',
  UNIT_MIX = 'unit-mix',
  DELINQUENCY = 'delinquency',
  RENEWALS = 'renewals',
  //Reports - end
  BID_DETAIL = 'bid-detail',
  APPLIANCE_DETAIL = 'appliance-detail',
  JOBS_AVAILABLE_AND_BIDS_SENT = 'jobs-available-and-bids-sent',
  BULK_BIDS_EDIT = 'bulk-bids-edit',
  EDIT = 'edit',
  UNIT_DETAILS = 'unitDetails',
  TICKETS = 'tickets',
  DASHBOARD_TICKETS = 'dashboardTickets',
  DETAILS = 'details',
  HISTORY = 'history',
  INSPECTIONS = 'inspections',
  CUSTOM_INSPECTIONS = 'inspections/custom',
  CALENDAR = 'calendar',
}
